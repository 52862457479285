import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Video, { VideoProps } from "./media-mentions/Video";

const Breakpoint = (props: { sx: any }) => {

  const videos: VideoProps[] = [
    {
      title: "Fast forward from Frankendancer to Firedancer",
      desc: "Breakpoint 2024: Keynote (Kevin Bowers)",
      descPreformatted: false,
      mediaUrl: "https://www.youtube.com/watch?v=8sl3RcN2Rdk",
      type: "Video",
      cover: "/resources/ff-frankendancer-to-firedancer-cover.png",
      date: "September 20, 2024",
    },
    {
      title: "Scenes from a Firedancer Validator",
      desc: "Breakpoint 2024: Workshop  (Liam Heeger, Aryaman Jain)",
      descPreformatted: false,
      mediaUrl: "https://www.youtube.com/watch?v=InGI7BDUeX4",
      type: "Video",
      cover: "/resources/scenes-from-a-firedancer-validator-cover.png",
      date: "September 21, 2024",
    },
    {
      title: "Cranking Up Frankendancer",
      desc: "Breakpoint 2024: Workshop (Anway De, Philip Taffet)",
      descPreformatted: false,
      mediaUrl: "https://www.youtube.com/watch?v=OxNmZEtIk3I",
      type: "Video",
      cover: "/resources/cranking-up-frankendancer-cover.png",
      date: "September 21, 2024",
    },
  ]

  return (
    <Box
      component="div" sx={{ ...props.sx }}>
      <Box
        component="div"
        sx={{
          px: 2,
          textAlign: "center",
          mb: 8,
        }}
      >
        <Typography variant="h2" sx={{ mb: 1.6 }}>
          Replaying&nbsp;
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            Breakpoint
          </Box>
        </Typography>

      </Box>

      {videos.map((video, i) => (
        <Video {...video} key={video.title} />
      ))}

    </Box>
  );
};

export default Breakpoint;
